// set basepath of project for webpack
import { basePath } from './core/config';
__webpack_public_path__ = `${basePath}/assets/frontend/js/`;

import './utils/object-foreach-polyfill';
import './utils/dataset-polyfill';
import './utils/promise-polyfill';
import './utils/string-includes-polyfill';
import 'console-polyfill';

import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)

import FastClick from 'fastclick';
import $ from 'jquery';

// jquery utils
import 'jquery.easing';

import './plugins/nette.ajax';
import './plugins/giant.trigger';
import './plugins/giant.autosubmit';
import './plugins/giant.show-if';

// nette
import './nette/NetteValidationMessages';
import './nette/NetteSnippets';

import Nette from './nette/NetteForms';
window.Nette = Nette;
Nette.initOnLoad();

if ($.nette) {
    $.nette.init();
}

import components from './components/';
import { loadComponents } from './core/componentUtils';

window.app = {
    components: components
};

FastClick.attach(document.body);
loadComponents();

if (document.querySelector('input[autofocus]') != null) {
    let val = document.querySelector('input[autofocus]').value;
    document.querySelector('input[autofocus]').value = '';
    document.querySelector('input[autofocus]').value = val;
}

