import components from '../components';
import Component from './Component';

export function loadComponents(context = document.documentElement) {
    if (!components || Object.keys(components).length === 0) {
        console.warn('App has no components');
        return;
    }

    return context.querySelectorAll('[data-component]').forEach((element) => {
        const instance = Component.getFromElement(element);

        if (instance) {
            console.warn('Error: instance exists: \n', instance);
            return true; // continue
        }

        let componentName = element.dataset.component;

        if (typeof components[componentName] === 'function') {
            createInstance(element, componentName)
        } else {
            console.warn(`Constructor for component "${componentName}" not found.`);
        }
    });
}

export function removeComponents(context = document.documentElement) {
    context.querySelectorAll('[data-component]').forEach(element => {
        destroyInstance(element);
    });
}

export const createInstance = (element, componentName) => {
    components[componentName].prototype._name = componentName;
    const component = new components[componentName](element);
    component._load();

    console.info(`Created instance of component "${componentName}".`);
    return component;
}

export const destroyInstance = element => {
    const instance = Component.getFromElement(element);
    if (instance) {
        const name = instance._name;
        instance.destroy();
        element['__giant_component__'] = null;
        console.info(`Removed component "${name}".`);
    }
}
