import Component from '../core/Component';

export default class Prompt extends Component {
    constructor(element) {
        super(element);
    }

    prepare() {
        this.message = this.element.dataset.message;
        this.errorMessage = this.element.dataset.errorMessage;
        this.value = this.element.dataset.value;
        this.element.addEventListener('click', event => {
            event.preventDefault();
            this.prompt();
        })
    }

    prompt() {
        const check = prompt(this.message)

        // cancel
        if (check == null) {
            return
        }

        if (check === this.value) {
            window.location = this.element.href
        } else {
            alert(this.errorMessage)
            this.prompt()
        }
    }
}