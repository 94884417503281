import Component from '../../js/core/Component';

export default class Form extends Component {
    prepare() {
        this.element.querySelectorAll('input').forEach(item => {
            // text inputs (email, tel, number)
            if (item.getAttribute('type') == null || item.getAttribute('type') == "text" || item.getAttribute('type') == "email" || item.getAttribute('type') == "tel" || item.getAttribute('type') == "number") {
                item.addEventListener('input', ::this.onTextInputChange);
                item.addEventListener('paste', ::this.onTextInputChange);
                item.addEventListener('onkeydown', ::this.onTextInputChange);
            }
            this.onTextInputChange({target: item});
        });
    }

    onTextInputChange(event) {
        setTimeout(() => {
            if (event.target.value == '') {
                event.target.parentNode.classList.remove('is-filled');
            } else {
                event.target.parentNode.classList.add('is-filled');
            }
        }, 10);
    }
}