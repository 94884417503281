import Component from '../core/Component';

export default class Alert extends Component {
    constructor(element) {
        super(element);
    }

    prepare() {
        this.ref.close.addEventListener('click', event => {
            event.preventDefault();
            this.element.outerHTML = "";
        });
    }
}