import Component from '../core/Component';

export default class CountChecked extends Component {
    constructor(element) {
        super(element);
    }

    prepare() {
        document.querySelectorAll(this.element.dataset.target).forEach(item => {
            item.addEventListener('change', ::this.check);
        });
        this.check();
    }

    check() {
        this.element.innerHTML = document.querySelectorAll(this.element.dataset.target + ':checked').length;
    }
}