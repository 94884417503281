import * as ViewportServices from '../../services/Viewport';
import $ from 'jquery';

document.addEventListener('nette:valid', event => {
    const element = event.target;

    if (element.tagName.toLowerCase() != 'button') {
        if (element.dataset.errorTarget != null && element.dataset.errorTarget != "") {
            let errorElement = document.querySelector(element.dataset.errorTarget).querySelector('.InputError');
            if (errorElement) {
                document.querySelector(element.dataset.errorTarget).removeChild(errorElement);
            }
        } else {
            let errorElement = element.parentNode.querySelector('.InputError');
            if (errorElement) {
                element.parentNode.removeChild(errorElement);
            }
        }

    }

    element.classList.remove('has-error');
    element.classList.add('is-valid');
});

document.addEventListener('nette:invalid', event => {
    const element = event.target;

    let errorElement = element.parentNode.querySelector('.InputError');

    if (element.dataset.errorTarget != null && element.dataset.errorTarget != "") {
        errorElement = document.querySelector(element.dataset.errorTarget).querySelector('.InputError');
    }

    if (!errorElement) {
        errorElement = document.createElement('div');
        errorElement.className = 'InputError';
    }

    errorElement.innerHTML = element.getAttribute('data-nette-error');
    if (element.dataset.errorTarget != null && element.dataset.errorTarget != "") {
        document.querySelector(element.dataset.errorTarget).appendChild(errorElement);
    } else {
        element.parentNode.appendChild(errorElement);
    }
    element.classList.add('has-error');
    element.classList.remove('is-valid');
});

document.addEventListener('nette:form:valid', event => console.info('Form valid', event.target));
document.addEventListener('nette:form:invalid', event => {
    const $form = $(event.target);
    const $error = $form.find('.InputError');
    let $target = $error.closest('.Input');
    ViewportServices.scrollToElement($target.length ? $target : $form, -120);
});