import Component from '../core/Component';

export default class InfoBox extends Component {
    constructor(element) {
        super(element);
    }

    prepare() {
        this.ref.icon.addEventListener('click', event => {
            event.preventDefault();
            setTimeout(() => {
                this.element.classList.toggle('is-active');
            }, 10)
        });
        this.ref.card.addEventListener('click', event => {
            if (event.target.tagName != "A") {
                event.preventDefault();
            }
            event.stopPropagation();
        });
        document.addEventListener('click', event => {
            this.element.classList.remove('is-active');
        });
    }
}