import Component from '../core/Component';

export default class ShowIf extends Component {
    constructor(element) {
        super(element);

        // options
        let options = {};
        if (this.element.dataset.options != null) {
            options = JSON.parse(this.element.dataset.options);
        }
        let defaults = {
            check: true,
        }

        this.options = {
            ...defaults,
            ...options
        }
    }

    prepare() {
        this.if = this.element.dataset.if;
        this.condition = this.if[0] == '!' ? 1 : 0;
        this.target = document.querySelector(this.if.replace('!'));

        this.target.addEventListener('change', ::this.check);
        this.check(true);
    }

    check(manual) {
        const event = document.createEvent('Event');
        event.initEvent('change', true, true);
        if (this.target.checked == !this.condition) {
            this.element.style.display = "block";
            if (manual != true) {
                this.element.querySelectorAll('input[type=checkbox]').forEach(item => {
                    if(this.options.check) {
                        item.checked = true;
                    }
                    item.dispatchEvent(event);
                });
            }
        } else {
            this.element.style.display = "none";
            if (manual != true) {
                this.element.querySelectorAll('input[type=checkbox]').forEach(item => {
                    item.checked = false;
                    item.dispatchEvent(event);
                });
            }
        }
    }
}