import Component from '../core/Component';

export default class Confirm extends Component {
    prepare() {
        this.message = this.element.dataset.message;
        this.errorMessage = this.element.dataset.errorMessage;
        this.value = this.element.dataset.value;
        this.element.addEventListener('click', event => {
            event.preventDefault();
            this.confirm();
        })
    }

    confirm() {
        if (confirm(this.message)) {
            window.location = this.element.href
        }
    }
}