import Component from '../core/Component';

export default class Tabs extends Component {
    constructor(element) {
        super(element);
    }

    prepare() {
        this.ref.controlItems.forEach(item => {
            item.addEventListener('click', ::this.clickHandler);
            item.addEventListener('keyup', event => {
                if(event.keyCode == 32) {
                    this.clickHandler(event);
                }
            });
        });

        let isSetFromUrl = false;

        if (window.location.hash != null && window.location.hash != "") {
            this.ref.controlItems.forEach(item => {
                if(window.location.hash == item.getAttribute('href')) {
                    console.log('asdasdasdasd')
                    item.click();
                    window.scrollTo(0, this.element.offsetTop);
                    isSetFromUrl = true;
                }
                if(window.location.hash.replace('-', '') == item.getAttribute('href')) {
                    this.clickHandler({preventDefault: function(){}, currentTarget: item}, false);
                    isSetFromUrl = true;
                }
            });
        }

        if(!isSetFromUrl) {
            this.ref.controlItems[0].parentNode.classList.add('is-active');
            this.ref.tabs[0].classList.add('is-active');
        }

        document.querySelectorAll('[data-tab]').forEach(item => {
            item.addEventListener('click', event => {
                this.ref.controlItems.forEach(controlItem => {
                    if (item.getAttribute('href') == controlItem.getAttribute('href')) {
                        controlItem.click();
                    }
                });
            });
        });
    }

    clickHandler(event, replaceState = true) {
        event.preventDefault();

        if (replaceState) {
            window.history.replaceState(null, null, event.currentTarget.getAttribute('href'));
        }

        // control
        this.ref.controlItems.forEach(item => item.parentNode.classList.remove('is-active'));
        event.currentTarget.parentNode.classList.add('is-active');

        // tabs
        this.ref.tabs.forEach(item => item.classList.remove('is-active'));
        this.element.querySelector(event.currentTarget.getAttribute('href')).classList.add('is-active');
    };
}