import Component from '../core/Component'
import { queryAll } from '../utils/dom'

export default class FacultyFilter extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            university: null,
            faculty: null
        }

        this.form = this.element.closest('form')
    }

    prepare() {
        this.faculties = queryAll('option', this.ref.faculty)
        this.ref.university.addEventListener('change', this.handleUniversityChange)
        this.form.addEventListener('submit', this.handleFormSubmit)

        this.render()
    }

    destroy() {
        this.form.removeEventListener('submit', this.handleFormSubmit)
    }

    handleFormSubmit = () => {
        // this.ref.faculty.disabled = false
    }

    handleUniversityChange = () => {
        this.render()
    }

    render() {
        const universityId = this.ref.university.value
        const faculties = this.faculties.filter(element => element.dataset.university === universityId)
        this.ref.faculty.disabled = universityId === '' || faculties.length === 0

        this.faculties.forEach(element => {
            if (element.value === '') {
                // skip empty value
            } else if (element.dataset.university === universityId) {
                this.ref.faculty.appendChild(element)
            } else {
                if (element.parentNode === this.ref.faculty) {
                    this.ref.faculty.removeChild(element)
                }
            }
        })
    }
}