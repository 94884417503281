// general
import Antispam from '../../components/Antispam';
import Form from '../../components/Form';
import CookieBubble from '../../components/CookieBubble';

// project
import SampleComponent from './SampleComponent'
import Alert from './Alert';
import Block from './Block';
import Confirm from './Confirm';
import CountChecked from './CountChecked';
import FacultyFilter from './FacultyFilter';
import InfoBox from './InfoBox';
import IsUpdated from './IsUpdated';
import Tabs from './Tabs';
import ShowIf from './ShowIf';
import Prompt from './Prompt';

const Components = {
    Alert,
    Antispam,
    Block,
    Confirm,
    CountChecked,
    FacultyFilter,
    Form,
    InfoBox,
    IsUpdated,
    CookieBubble,
    Tabs,
    ShowIf,
    Prompt,
}

export default Components