import Component from '../core/Component';

export default class IsUpdated extends Component {
    constructor(element) {
        super(element);
    }

    prepare() {
        this.element.querySelectorAll('input, select').forEach(item => {
            item.originalValue = item.value;
            item.addEventListener('change', ::this.handleChange);
            item.addEventListener('blur', ::this.handleChange);
        });
    }

    handleChange() {
        let changed = false;
        this.element.querySelectorAll('input, select').forEach(item => {
            if (item.originalValue != item.value) {
                changed = true;
            }
        });

        if (changed) {
            this.element.classList.add('has-changed');
        } else {
            this.element.classList.remove('has-changed');
        }
    }
}