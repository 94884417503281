import Component from '../core/Component';

export default class Block extends Component {
    constructor(element) {
        super(element);
    }

    prepare() {
        if (this.ref.head) {
            this.ref.head.addEventListener('click', event => {
                event.preventDefault();
                this.element.classList.toggle('is-active');
            });
        } else {
            this.element.classList.add('is-active');
        }
    }
}